import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "form",
    model: $props.user,
    rules: $data.rules,
    "label-width": "80px"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "用户昵称",
      prop: "nickName"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $props.user.nickName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $props.user.nickName = $event),
        maxlength: "30"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "手机号码",
      prop: "phonenumber"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $props.user.phonenumber,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $props.user.phonenumber = $event),
        maxlength: "11"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "邮箱",
      prop: "email"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $props.user.email,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $props.user.email = $event),
        maxlength: "50"
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "性别"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_radio_group, {
        modelValue: $props.user.sex,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $props.user.sex = $event)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_radio, {
          label: "0"
        }, {
          default: _withCtx(() => [_createTextVNode("男")]),
          _: 1
        }), _createVNode(_component_el_radio, {
          label: "1"
        }, {
          default: _withCtx(() => [_createTextVNode("女")]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        type: "primary",
        size: "mini",
        onClick: $options.submit
      }, {
        default: _withCtx(() => [_createTextVNode("保存")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_button, {
        type: "danger",
        size: "mini",
        onClick: $options.close
      }, {
        default: _withCtx(() => [_createTextVNode("关闭")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"]);
}