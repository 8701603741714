/*
 * @Author: your name
 * @Date: 2022-10-27 17:14:00
 * @LastEditTime: 2022-10-30 13:42:01
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \piggtex-ui\src\router\index.js
 */
import { createRouter, createWebHashHistory } from "vue-router"/* Layout */
// import index_layout from '@/views/layout/index'
// import admin_layout from '@/views/layout/admin'

// Auto Load Routers
  const requireViews  = require.context(`../views/admin`, true, /\w+\.vue$/)
  const admin_routes = requireViews.keys().map((viewPath, index) => {
    let viewName = viewPath.split('.vue')[0].substr(2);
      return {
        path: viewName === 'index' ? '/' : `${viewName}`,
        name: viewName,
        meta:{title:viewName,name:`PigGTEx-${viewName}`},
        component: () => import(`../views/admin/${viewName}.vue`)
      }
  });
  sessionStorage.setItem('admin_routes',JSON.stringify(admin_routes) )


const routes = [
  {

    path: "/",
    redirect:"/home",
    component:() => import('@/views/layout/index'),
    children: [
      {
        path: "home",
        component:  () => import('@/views/moqtl_home'),
        meta:{
            title: "PigGTEx-Portal",
        }
      },
      {
        path: "igv_browser",
        component:  () => import('@/views/igv_browser'),
        meta:{
            title: "PigGTEx-IGVBrowser",
        }
      },
      {
        path: "gene",
        component: () => import('@/views/molqtl_gene'),
        meta:{
            title: "PigGTEx-SearchGene",
        }
      },
      {
          path: "variant",
          component: () => import('@/views/molqtl_variant'),
          meta:{
              title: "PigGTEx-SearchVariant",
          }
      },
      {
          path: "contact_join",
          component: () => import('@/views/contact_join'),
          meta:{
              title: "PigGTEx-ContactJoin",
          }
      },
      {
          path: "about_piggtex",
          component: () => import('@/views/about_piggtex'),
          meta:{
              title: "PigGTEx-About",
          }
      },
      {
          path: "publications",
          component: () => import('@/views/publications'),
          meta:{
              title: "PigGTEx-Publications",
          }
      },
      {
          path: "downloads",
          component: () => import('@/views/downloads'),
          meta:{
              title: "PigGTEx-Downloads",
          }
      },
      {
        path: "subscribe",
        component: () => import('@/views/subscribe'),
        meta:{
            title: "PigGTEx-Subscribe",
        }
      },
      {
        path: "login",
        component: () => import('@/views/admin/login'),
        meta:{
            title: "PigGTEx-Login",
        }
      }
    ],
  },
  {
    path: "/admin",
    redirect:"/admin/syslog",
    component: () => import('@/views/layout/admin'),
    children:admin_routes

  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () =>import ("../views/404.vue")
  },
]








const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
