

import { postToServer, getFromServer,service } from '@/utils/requests.js'
import { parseStrEmpty } from "@/utils";

export function login(params){
  return postToServer('login', params)
}

export function getCaptchaImage(){
  return getFromServer('captchaImage',{})
}
export function getList(query) {
    return getFromServer('piggtex/info/list',query)
}

// 查询系统访问记录列表
export function listView(query) {
  return service({
    url: 'system/view/list',
    method: 'get',
    params: query
  })
}

// 查询系统访问记录详细
export function getView(viewId) {
  return service({
    url: 'system/view/' + viewId,
    method: 'get'
  })
}

// 新增系统访问记录
export function addView(data) {
  return service({
    url: 'system/view',
    method: 'post',
    data: data
  })
}

// 修改系统访问记录
export function updateView(data) {
  return service({
    url: 'system/view',
    method: 'put',
    data: data
  })
}

// 删除系统访问记录
export function delView(viewId) {
  return service({
    url: 'system/view/' + viewId,
    method: 'delete'
  })
}

// 查询用户列表
export function listUser(query) {
  return service({
    url: 'system/user/list',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getUser(userId) {
  return service({
    url: 'system/user/' + parseStrEmpty(userId),
    method: 'get'
  })
}

// 新增用户
export function addUser(data) {
  return service({
    url: 'system/user',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser(data) {
  return service({
    url: 'system/user',
    method: 'put',
    data: data
  })
}

// 删除用户
export function delUser(userId) {
  return service({
    url: 'system/user/' + userId,
    method: 'delete'
  })
}

// 用户密码重置
export function resetUserPwd(userId, password) {
  const data = {
    userId,
    password
  }
  return service({
    url: 'system/user/resetPwd',
    method: 'put',
    data: data
  })
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status
  }
  return service({
    url: 'system/user/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return service({
    url: 'system/user/profile',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return service({
    url: 'system/user/profile',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return service({
    url: 'system/user/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return service({
    url: 'system/user/profile/avatar',
    method: 'post',
    data: data
  })
}

// 查询授权角色
export function getAuthRole(userId) {
  return service({
    url: 'system/user/authRole/' + userId,
    method: 'get'
  })
}

// 保存授权角色
export function updateAuthRole(data) {
  return service({
    url: 'system/user/authRole',
    method: 'put',
    params: data
  })
}

  

