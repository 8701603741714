import { ElMessageBox } from 'element-plus/es';
import 'element-plus/es/components/message-box/style/css';
import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { listView, getView, delView, addView, updateView } from "@/utils/api";
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
export default {
  __name: 'syslog',
  setup(__props) {
    const form_ref = ref(null);
    const loading = ref(false);
    const ids = ref([]);
    const single = ref(true);
    const multiple = ref(true);
    const showSearch = ref(true);
    const total = ref(0);
    const viewList = ref([]);
    const title = "";
    const open = ref(false);
    const queryParams = ref({
      pageNum: 1,
      pageSize: 10,
      ipaddr: null,
      loginLocation: null,
      browser: null,
      os: null,
      type: null,
      value: null,
      searchTime: null
    });
    const form = ref({});
    const rules = {};

    /** 查询系统访问记录列表 */
    function getList() {
      loading.value = true;
      listView(queryParams.value).then(response => {
        viewList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    // 取消按钮
    function cancel() {
      open.value = false;
      reset();
    }
    // 表单重置
    function reset() {
      form.value = {
        viewId: null,
        ipaddr: null,
        loginLocation: null,
        browser: null,
        os: null,
        type: null,
        value: null,
        searchTime: null
      };
      // resetForm("form");
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.value.pageNum = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      resetForm("queryForm");
      handleQuery();
    }
    // 多选框选中数据
    function handleSelectionChange(selection) {
      ids.value = selection.map(item => item.viewId);
      single.value = selection.length !== 1;
      multiple.value = !selection.length;
    }
    /** 新增按钮操作 */
    function handleAdd() {
      reset();
      open.value = true;
      title.value = "添加系统访问记录";
    }
    /** 修改按钮操作 */
    function handleUpdate(row) {
      reset();
      const viewId = row.viewId || ids.value;
      getView(viewId).then(response => {
        form.value = response.data;
        open.value = true;
        title.value = "修改系统访问记录";
      });
    }
    /** 提交按钮 */
    function submitForm() {
      form_ref.value.validate(valid => {
        if (valid) {
          if (form_ref.value.viewId != null) {
            updateView(form.value).then(response => {
              ElMessage.success('修改成功');
              open.value = false;
              getList();
            });
          } else {
            addView(form.value).then(response => {
              ElMessage.success('新增成功');
              open.value = false;
              getList();
            });
          }
        }
      });
    }
    /** 删除按钮操作 */
    function handleDelete(row) {
      const viewIds = row.viewId || ids.value;
      ElMessageBox.confirm(`是否确认删除系统访问记录编号为${viewIds}的数据项？?`, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        return delView(viewIds);
      }).then(() => {
        getList();
        ElMessage.success('删除成功');
      });

      // this.$modal.confirm('是否确认删除系统访问记录编号为"' + viewIds + '"的数据项？').then(function() {
      //   return delView(viewIds);
      // }).then(() => {
      //   this.getList();
      //   this.$modal.msgSuccess("删除成功");
      //   ElMessage.success('删除成功');
      // }).catch(() => {});
    }
    /** 导出按钮操作 */
    function handleExport() {
      download('system/view/export', {
        ...queryParams.value
      }, `view_${new Date().getTime()}.xlsx`);
    }
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_el_form, {
        model: queryParams.value,
        ref: "queryForm",
        size: "small",
        inline: true,
        "label-width": "68px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "访问IP地址",
          prop: "ipaddr"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: queryParams.value.ipaddr,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => queryParams.value.ipaddr = $event),
            placeholder: "请输入访问IP地址",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter", "native"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "访问地点",
          prop: "loginLocation"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: queryParams.value.loginLocation,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => queryParams.value.loginLocation = $event),
            placeholder: "请输入访问地点",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter", "native"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "浏览器类型",
          prop: "browser"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: queryParams.value.browser,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => queryParams.value.browser = $event),
            placeholder: "请输入浏览器类型",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter", "native"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "操作系统",
          prop: "os"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: queryParams.value.os,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => queryParams.value.os = $event),
            placeholder: "请输入操作系统",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter", "native"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "查询值",
          prop: "value"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: queryParams.value.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => queryParams.value.value = $event),
            placeholder: "请输入查询值",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter", "native"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "查询时间",
          prop: "searchTime"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: queryParams.value.searchTime,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => queryParams.value.searchTime = $event),
            type: "date",
            placeholder: "请选择查询时间",
            "value-format": "YYYY-MM-DD"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "el-icon-search",
            size: "mini",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), [[_vShow, showSearch.value]]), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "success",
            plain: "",
            icon: "el-icon-edit",
            size: "mini",
            disabled: single.value,
            onClick: handleUpdate
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 1
          }, 8, ["disabled"])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "el-icon-delete",
            size: "mini",
            disabled: multiple.value,
            onClick: handleDelete
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 1
          }, 8, ["disabled"])]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: viewList.value,
        onSelectionChange: handleSelectionChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "55",
          align: "center"
        }), _createVNode(_component_el_table_column, {
          label: "访问ID",
          align: "center",
          prop: "viewId"
        }), _createVNode(_component_el_table_column, {
          label: "访问IP地址",
          align: "center",
          prop: "ipaddr"
        }), _createVNode(_component_el_table_column, {
          label: "访问地点",
          align: "center",
          prop: "loginLocation"
        }), _createVNode(_component_el_table_column, {
          label: "浏览器类型",
          align: "center",
          prop: "browser"
        }), _createVNode(_component_el_table_column, {
          label: "操作系统",
          align: "center",
          prop: "os"
        }), _createVNode(_component_el_table_column, {
          label: "查询类型",
          align: "center",
          prop: "type"
        }), _createVNode(_component_el_table_column, {
          label: "查询值",
          align: "center",
          prop: "value"
        }), _createVNode(_component_el_table_column, {
          label: "查询时间",
          align: "center",
          prop: "searchTime",
          width: "180"
        }, {
          default: _withCtx(scope => []),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          align: "center",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            size: "mini",
            type: "text",
            icon: "el-icon-edit",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            size: "mini",
            type: "text",
            icon: "el-icon-delete",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, loading.value]]), _createVNode(_component_el_pagination, {
        total: total.value,
        currentPage: queryParams.value.pageNum,
        "onUpdate:currentPage": _cache[6] || (_cache[6] = $event => queryParams.value.pageNum = $event),
        "page-size": queryParams.value.pageSize,
        "onUpdate:page-size": _cache[7] || (_cache[7] = $event => queryParams.value.pageSize = $event),
        onCurrentChange: getList
      }, null, 8, ["total", "currentPage", "page-size"]), _createVNode(_component_el_dialog, {
        title: title,
        modelValue: open.value,
        "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => open.value = $event),
        width: "500px",
        "append-to-body": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "form_ref",
          ref: form_ref,
          model: form.value,
          rules: rules,
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "访问IP地址",
            prop: "ipaddr"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.value.ipaddr,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => form.value.ipaddr = $event),
              placeholder: "请输入访问IP地址"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "访问地点",
            prop: "loginLocation"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.value.loginLocation,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => form.value.loginLocation = $event),
              placeholder: "请输入访问地点"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "浏览器类型",
            prop: "browser"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.value.browser,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => form.value.browser = $event),
              placeholder: "请输入浏览器类型"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "操作系统",
            prop: "os"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.value.os,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => form.value.os = $event),
              placeholder: "请输入操作系统"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "查询值",
            prop: "value"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: form.value.value,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => form.value.value = $event),
              placeholder: "请输入查询值"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "查询时间",
            prop: "searchTime"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_date_picker, {
              clearable: "",
              modelValue: form.value.searchTime,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => form.value.searchTime = $event),
              type: "date",
              "value-format": "yyyy-MM-dd",
              placeholder: "请选择查询时间"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};