import { createApp } from 'vue'
import App from './App.vue'
// import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import router from './router'
import { postToServer, getFromServer } from '@/utils/requests.js'
import './permission'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

app.config.globalProperties.postToServer = postToServer;
app.config.globalProperties.getFromServer = getFromServer;

app.use(router)

const VueScrollTo = require('vue-scrollto');
app.use(VueScrollTo, {
    container: "body", //滚动的容器
    duration: 500, //滚动时间
    easing: "ease", //缓动类型
    offset: -120, //滚动时应应用的偏移量。此选项接受回调函数
    force: true, //是否应执行滚动
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});

app.mount('#app')

