
import router from './router'

const blackList = ['/admin']
const hasToken = sessionStorage.getItem('token')
let reg = new RegExp(blackList[0], 'i')


function getMenu(routes){
   
    let admin_menus = routes.map(item=>{
      if(!item.children)
        return {url:`${item.path}`,title:item.meta.title}
      else{
        let children = getMenu(item.children);
        return {url:`${item.path}`,title:item.meta.title,children:children};
      }
    })
    return admin_menus;
}
let admin_routes = JSON.parse( sessionStorage.getItem('admin_routes') );
let menus = getMenu(admin_routes)
sessionStorage.setItem('menus',JSON.stringify(menus));

router.beforeEach((to, from, next) => {
    const hasToken = sessionStorage.getItem('token');
    if (hasToken) {
        next()
    }
    else {

        if (reg.test(to.path)) {
            console.log('UnAuthority')
            next(`/login?redirect=${to.path}`)
        }
        else {
            next();
        }
    }

})