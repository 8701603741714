import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "form",
    model: $data.user,
    rules: $data.rules,
    "label-width": "80px"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form_item, {
      label: "旧密码",
      prop: "oldPassword"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.user.oldPassword,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.user.oldPassword = $event),
        placeholder: "请输入旧密码",
        type: "password",
        "show-password": ""
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "新密码",
      prop: "newPassword"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.user.newPassword,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.user.newPassword = $event),
        placeholder: "请输入新密码",
        type: "password",
        "show-password": ""
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, {
      label: "确认密码",
      prop: "confirmPassword"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_input, {
        modelValue: $data.user.confirmPassword,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.user.confirmPassword = $event),
        placeholder: "请确认密码",
        type: "password",
        "show-password": ""
      }, null, 8, ["modelValue"])]),
      _: 1
    }), _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        type: "primary",
        size: "mini",
        onClick: $options.submit
      }, {
        default: _withCtx(() => [_createTextVNode("保存")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_button, {
        type: "danger",
        size: "mini",
        onClick: $options.close
      }, {
        default: _withCtx(() => [_createTextVNode("关闭")]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["model", "rules"]);
}