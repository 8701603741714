import axios from 'axios'
// import { getToken } from '@/utils/auth'

import { ElMessage } from 'element-plus'

export const service = axios.create({
  baseURL: '', // url = base url + request url
  timeout: 180000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    config.url = `${process.env.VUE_APP_BASE_API}${config.url}`

    if ( sessionStorage.getItem('token') )
    { config.headers['Authorization'] = `Bearer ${ sessionStorage.getItem('token')}` }
    if(sessionStorage.getItem('ip'))
      config.headers['x-forwarded-for'] = sessionStorage.getItem('ip');
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params)
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  
  response => {
    const res = response.data
    const statusCode = response.status

    // If download
    if (response.config.isDownload && statusCode == 200) {
      return res
    }

    if (statusCode == 200) {
      const code = res.code
      if (code && code == 200) {
        return res
      }
      else{
  
        ElMessage.error(res.msg || 'Error')
        return Promise.reject(res)
      }
    }
    else {

      
      ElMessage.error(res.msg || 'Error')
      return Promise.reject(res)
    }
  },
  error => {
    const response = error.response;
    if (!response)
    { return Promise.reject(error) }

    // if (store.state.user.token == '') {
    //   return Promise.reject(response)
    // }
    if (response.status == 401) {
      
    } else if (response.status == 500) {
     
     
    }
    else {
      
    }
    return Promise.reject(response)
  }
)

function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    var part = encodeURIComponent(propName) + '='
    if (value !== null && value !== '' && typeof (value) !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== '' && typeof (value[key]) !== 'undefined') {
            const params = propName + '[' + key + ']'
            var subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
      }
    }
  }
  return result
}


export function postToServer(url, params, isBody=true) {
  if(isBody){
    return service({
      url: url,
      method: 'post',
      data: params
    })
  }
  else{
    return service({
      url: url,
      method: 'post',
      params: params
    })
  }

}

export function getFromServer(url, params) {
  if(url=='getCode'){
    return service({
      url: url,
      method: 'get',
      params: params,
      responseType:'arraybuffer',
    })
  }
  else{
    return service({
      url: url,
      method: 'get',
      params: params,
    })
  }
   

}

// export default service
